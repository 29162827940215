import { RadiusValue, Filters } from 'ha/types/SearchFilters';

export interface FilterQueryParams extends Filters {
  page: number | undefined;
}

export const RequiredAnalyticsFilters = {
  endDate: '',
  startDate: '',
  priceMax: '',
  priceMin: '',
  categories: [],
  furniture: [],
  advRating: [],
  rooms: { bedroomCount: [] },
  bills: [],
  suitableFor: [],
  registration: [],
  recentlyAdded: null,
  rules: [],
  gender: [],
  contractType: [],
  sizeMin: '',
  sizeMax: '',
  facilities: [],
  amenities: [],
  radius: RadiusValue.DEFAULT,
};

export type CollectionToFilters = Pick<
  Filters,
  | 'startDate'
  | 'categories'
  | 'priceMin'
  | 'priceMax'
  | 'currency'
  | 'furniture'
  | 'suitableFor'
  | 'bills'
  | 'bedroomCount'
>;
