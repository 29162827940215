import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { Tabs, Typography } from '@hbf/dsl/core';

import { ScreenSize } from 'ha/modules/UI/constants';

import { TabPanel } from 'ha/components/StyledTabs';

import { useTrackEvent } from '../Analytics/helpers/TrackEvent';
import { usePage } from '../Page/contexts';

interface TabData {
  value: number;
  label: string;
  testId: string;
  tabName: string;
  content: React.ReactNode;
}

interface TabsContainerProps {
  tabsData: TabData[];
  className?: string;
  rootClassName?: string;
}

const useStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.utils.spacing('-ref/spacing/6'),
    marginBottom: theme.utils.spacing('ref/spacing/8'),
    [theme.breakpoints.down(ScreenSize.MD)]: {
      marginTop: theme.utils.spacing('ref/spacing/0'),
    },
  },
  tabs: {
    marginInline: 'auto',
    maxWidth: '540px',
    marginBottom: theme.utils.spacing('ref/spacing/10'),
  },
}));

export const ListingsTabContainer: React.FC<TabsContainerProps> = ({
  tabsData,
  className,
  rootClassName,
}) => {
  const { classes, cx } = useStyles();
  const [currentTab, setCurrentTab] = React.useState(0);
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const { category } = usePage();
  const trackEvent = useTrackEvent();

  const handleTabChange = React.useCallback(
    (value: React.SetStateAction<number>) => {
      if (value !== currentTab) {
        setCurrentTab(value);

        const tabName = tabsData[value as number]?.tabName;
        trackEvent('Tab Changed', {
          tab_name: tabName,
          page: category,
        });
      }
    },
    [currentTab, useTrackEvent],
  );

  const getTypographyVariant = React.useCallback(
    (tabValue: number) => {
      const isActive = currentTab === tabValue;
      if (isLargerThanMd) {
        return isActive
          ? 'heading/desktop/h4-semibold'
          : 'heading/desktop/h4-regular';
      }
      return isActive ? 'body/lg-semibold' : 'body/lg-regular';
    },
    [currentTab, isLargerThanMd],
  );

  return (
    <div
      className={cx(classes.root, rootClassName)}
      data-test-locator="ListingsTabContainer"
    >
      <Tabs
        size="sm"
        value={currentTab}
        onChange={(_: React.ChangeEvent<HTMLSelectElement>, value: number) => {
          handleTabChange(value);
        }}
        className={className || classes.tabs}
      >
        {tabsData.map(tab => (
          <Tabs.Item
            value={tab.value}
            key={tab.value}
            data-test-locator={`ListingsTabContainer/${tab.testId}`}
          >
            <Typography variant={getTypographyVariant(tab.value)}>
              {tab.label}
            </Typography>
          </Tabs.Item>
        ))}
      </Tabs>
      {tabsData.map(tab => (
        <TabPanel
          currentTabIndex={currentTab}
          index={tab.value}
          key={tab.value}
        >
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
};
