import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { Element as ScrollElement } from 'react-scroll';
import { makeStyles } from 'tss-react/mui';

import { defineKey } from '@ha/intl';
import { Link, Typography } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';
import { CheckCircle } from '@hbf/icons/brand-regular';

import { useConfig } from 'ha/helpers/config';
import { useScrollObserver } from 'ha/helpers/hooks/useScrollObserver';
import { useIntl } from 'ha/i18n';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';
import { ImgixSize } from 'ha/modules/Image/constants';
import { Imgix } from 'ha/modules/Image/Imgix';
import { usePage } from 'ha/modules/Page/contexts/PageContext';

import { HOME_IMGIX_PATH } from '../../constants';
import { SectionLayout } from '../../layouts';

// The min and max element's position can change
const IMAGE_MARGIN = 160;

// To display the title above the image
const SECTION_TITLE_Z_INDEX = 1;

const useStyles = makeStyles()(theme => ({
  root: {
    '--home-guaranteed-image-position': `${IMAGE_MARGIN}px`,
  },
  container: {
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
      marginBottom: theme.spacing(8),
    },
  },
  sectionTitle: {
    color: theme.palette.neutral[100],
    zIndex: SECTION_TITLE_Z_INDEX,
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `
      "icon title"
      "icon message"
    `,
    rowGap: theme.utils.spacing('ref/spacing/1'),
    columnGap: theme.utils.spacing('ref/spacing/3'),
    padding: 0,
    color: theme.palette.neutral[100],
  },
  benefitIcon: {
    gridArea: 'icon',
    width: theme.utils.spacing('ref/spacing/8'),
    height: theme.utils.spacing('ref/spacing/8'),
  },
  benefitTitle: {
    gridArea: 'title',
  },
  benefitMessage: {
    gridArea: 'message',
  },
  learnMore: {
    color: theme.palette.neutral[100],
    marginLeft: `calc(${theme.utils.spacing(
      'ref/spacing/8',
    )} + ${theme.utils.spacing('ref/spacing/3')})`,
  },
  image: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      // TODO: change this to read from shape object when it's ready.
      borderRadius: '4px',
      marginBottom: `calc(0px - ${theme.spacing(8)})`,
      transition: 'transform 100ms ease-out',
      transform: 'translateY(var(--home-guaranteed-image-position))',
    },
  },
  aboveViewPort: {
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(0)',
    },
  },
  underViewPort: {
    [theme.breakpoints.up('md')]: {
      transform: 'translateY(-30%)',
    },
  },
}));

const BENEFITS_LIST = [
  {
    label: defineKey('home.value_prop.move_in_name'),
    message: defineKey('home.value_prop.move_in_info'),
  },
  {
    label: defineKey('home.value_prop.support_name'),
    message: defineKey('home.value_prop.support_info'),
  },
  {
    label: defineKey('home.value_prop.refund_name'),
    message: defineKey('home.value_prop.refund_info'),
  },
];

export const GuaranteedSection = () => {
  const { T, urlResolver } = useIntl();
  const { classes } = useStyles();
  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const { imgix } = useConfig();
  const imgSrc = `${imgix.sourceURL}/${HOME_IMGIX_PATH}/guaranteed.jpg`;
  const { category } = usePage();
  const { createEventListener, isSectionVisible, sectionRef } =
    useScrollObserver(
      // Since the image is outside the section,
      // we have to set a root margin for intersection observer
      { rootMargin: `${IMAGE_MARGIN}px` },
    );

  React.useEffect(() => {
    const section = sectionRef.current;

    if (!section || !isLargerThanMd || !isSectionVisible) return undefined;

    const getCurrentPosition = (element: HTMLDivElement): number => {
      // The number of multiples for the root margin to give the scroll a smooth parallax effect
      const ROOT_MARGIN_MULTIPLY = 3;
      // The min and max above and bottom of the section that animation stops
      const ANIMATION_MARGIN = IMAGE_MARGIN * ROOT_MARGIN_MULTIPLY;

      const VIEWPORT_HEIGHT = window.innerHeight;
      const MIDDLE_OF_VIEWPORT = VIEWPORT_HEIGHT / 2;

      // Get element's bounding box
      const { top, height } = element.getBoundingClientRect();

      const middleOfElement = top + height / 2;

      const isAboveViewPort = middleOfElement > MIDDLE_OF_VIEWPORT;
      const elementMargin = Math.abs(middleOfElement - MIDDLE_OF_VIEWPORT);
      const shouldAnimate = elementMargin < ANIMATION_MARGIN;

      if (!shouldAnimate) {
        return isAboveViewPort ? IMAGE_MARGIN : -IMAGE_MARGIN;
      }

      if (isAboveViewPort) {
        const position = elementMargin / ROOT_MARGIN_MULTIPLY;
        return position;
      }

      const position = elementMargin / ROOT_MARGIN_MULTIPLY;
      return -position;
    };

    const changeElementPosition = () => {
      sectionRef.current?.style.setProperty(
        '--home-guaranteed-image-position',
        `${getCurrentPosition(section)}px`,
      );
    };
    changeElementPosition();

    const { removeEventListener } = createEventListener(changeElementPosition);

    return () => removeEventListener();
  }, [createEventListener, isLargerThanMd, isSectionVisible, sectionRef]);

  return (
    // eslint-disable-next-line custom/no-restricted-translation-jsx-untranslated-prop-text
    <ScrollElement name="HomePage.GuaranteedSection.Handle">
      <div ref={sectionRef} data-test-locator="GuaranteedSectionNext">
        {!isLargerThanMd && (
          <Imgix
            src={imgSrc}
            className={classes.image}
            width={ImgixSize.xsmall.w}
            imgixParams={{ fit: 'clip', ar: '1:1' }}
            htmlAttributes={{ alt: 'Happy move-in', loading: 'lazy' }}
          />
        )}

        <SectionLayout className={classes.container}>
          <SectionLayout.Container>
            <Grid container spacing={5}>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                gap={7}
              >
                <Typography
                  variant={
                    isLargerThanMd ? 'display/desktop/lg' : 'display/mobile/lg'
                  }
                  className={classes.sectionTitle}
                >
                  {T('ha_value_prop.name')}
                </Typography>
                <Grid container direction="column" spacing={5}>
                  {BENEFITS_LIST.map(benefit => (
                    <Grid item className={classes.listItem} key={benefit.label}>
                      <CheckCircle className={classes.benefitIcon} />
                      <Typography
                        variant={
                          isLargerThanMd
                            ? 'heading/desktop/h4-semibold'
                            : 'heading/mobile/h3-semibold'
                        }
                        className={classes.benefitTitle}
                      >
                        {T(benefit.label)}
                      </Typography>
                      <Typography
                        variant={
                          isLargerThanMd
                            ? 'body/lg-regular'
                            : 'heading/mobile/h4-regular'
                        }
                        className={classes.benefitMessage}
                      >
                        {T(benefit.message)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Grid container>
                  <Grid item>
                    <Track
                      name="Clicked 48-hour safeguard"
                      originated="Home"
                      pageCategory={category}
                    >
                      <Link
                        target="_blank"
                        href={urlResolver.getSecurePaymentUrl()}
                        size="lg"
                        className={classes.learnMore}
                        underline="always"
                      >
                        {T('cta.learn_tenant_protection')}
                      </Link>
                    </Track>
                  </Grid>
                </Grid>
              </Grid>
              {isLargerThanMd && (
                <Grid item md={6}>
                  <Imgix
                    src={imgSrc}
                    className={classes.image}
                    width={ImgixSize.small.w}
                    imgixParams={{ fit: 'clip', ar: '1:1' }}
                    htmlAttributes={{
                      alt: 'Happy move-in',
                      loading: 'lazy',
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </SectionLayout.Container>
        </SectionLayout>
      </div>
    </ScrollElement>
  );
};
