import React from 'react';

import { useTheme } from '@mui/material';

import { track } from '@hbf/analytics';

import { HOME } from 'ha/constants/pageNames';

import { Organization, WebSite } from 'ha/components.legacy/LinkedData';
import { useRecentlyViewedListings } from 'ha/modules/RecentlyViewed';

import {
  HeroSection,
  USPSection,
  StepsToBook,
  FeaturedCities,
  GuaranteedSection,
  PopularCities,
  FAQSection,
  TestimonialsSection,
  FindYourHomeSection,
  RecentlyViewedListings,
} from '../sections';

import { HomeHelmet } from './HomeHelmet';

export const RECENTLY_VIEWED_EVENT_NAME = 'Recently Viewed Loaded';

const HomeLayout: React.FC = () => {
  const theme = useTheme();
  const searchInputRef = React.useRef(null);

  const setSearchInputFocus = React.useCallback(() => {
    if (searchInputRef.current) {
      (searchInputRef.current as HTMLInputElement).focus();
    }
  }, [searchInputRef]);

  const { hasRecentlyViewedListing } = useRecentlyViewedListings();

  const heroSectionDynamicFooterColor = React.useMemo(() => {
    if (hasRecentlyViewedListing) {
      return theme.palette.neutral[100];
    }

    return undefined;
  }, [hasRecentlyViewedListing, theme.palette.neutral]);

  React.useEffect(() => {
    if (hasRecentlyViewedListing) {
      track(RECENTLY_VIEWED_EVENT_NAME, {
        page: HOME,
      });
    }
  }, [hasRecentlyViewedListing]);

  return (
    <div>
      <HomeHelmet />
      <Organization />
      <WebSite />
      <HeroSection
        searchInputRef={searchInputRef}
        hideDesktopBanner={hasRecentlyViewedListing}
        footerSectionColor={heroSectionDynamicFooterColor}
      />
      {hasRecentlyViewedListing && <RecentlyViewedListings />}
      <USPSection />
      <PopularCities />
      <GuaranteedSection />
      <StepsToBook />
      <TestimonialsSection />
      <FindYourHomeSection setSearchInputFocus={setSearchInputFocus} />
      <FeaturedCities />
      <FAQSection />
    </div>
  );
};

export { HomeLayout };
