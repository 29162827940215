import { addMonths, diffDay } from '@ha/date';

import { constants as commonConstants } from 'ha/constants/commonConstants';

const { listingFlexibleDays } = commonConstants;

const calculateMinimumSelectedDays = (
  startDate: string | Date,
  minimalPeriod: number,
) => {
  if (!minimalPeriod) {
    return 0;
  }

  return (
    diffDay(addMonths(startDate, minimalPeriod), startDate) -
    listingFlexibleDays
  );
};

export { calculateMinimumSelectedDays };
