import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';

import { useIntl } from 'ha/i18n';

import { SectionLayout } from '../../layouts';

const useStyles = makeStyles()(theme => ({
  title: {
    marginBottom: theme.utils.spacing('ref/spacing/10'),
    display: 'inline-block',
  },
  itemNumber: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.utils.spacing('ref/spacing/6'),
    },
  },
  itemTitle: {
    marginBottom: theme.utils.spacing('ref/spacing/2'),
    paddingTop: theme.utils.spacing('ref/spacing/1'),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.utils.spacing('ref/spacing/3'),
      paddingTop: 0,
    },
  },
  stepsToBook: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: theme.utils.spacing('ref/spacing/8'),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.utils.spacing('ref/spacing/10'),
    },
  },
}));
const StepToBook = ({
  number,
  title,
  description,
}: {
  number: string;
  title: string;
  description: string;
}) => {
  const { classes } = useStyles();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      direction={isLargerThanMd ? 'column' : 'row'}
      wrap="nowrap"
      spacing={isLargerThanMd ? undefined : 5}
    >
      <Grid item>
        <Typography
          variant={isLargerThanMd ? 'display/desktop/lg' : 'display/mobile/lg'}
          className={classes.itemNumber}
        >
          {number}
        </Typography>
      </Grid>
      <Grid item>
        <div className={classes.itemTitle}>
          <Typography
            variant={
              isLargerThanMd
                ? 'heading/desktop/h3-semibold'
                : 'heading/mobile/h3-semibold'
            }
          >
            {title}
          </Typography>
        </div>
        <div>
          <Typography variant="body/lg-regular">{description}</Typography>
        </div>
      </Grid>
    </Grid>
  );
};
export const StepsToBook = () => {
  const { classes } = useStyles();
  const { T } = useIntl();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <SectionLayout>
      <SectionLayout.Container>
        <Typography
          data-test-locator="Steps To Book Title"
          variant={isLargerThanMd ? 'heading/desktop/h2' : 'heading/mobile/h2'}
          className={classes.title}
        >
          {T('home.how_it_works.title')}
        </Typography>
        <div className={classes.stepsToBook}>
          <StepToBook
            number={T('home.how_it_works.1_number')}
            title={T('home.how_it_works.1_listings')}
            description={T('home.how_it_works.1_listings_description')}
          />
          <StepToBook
            number={T('home.how_it_works.2_number')}
            title={T('home.how_it_works.2_message')}
            description={T('home.how_it_works.2_message_description')}
          />
          <StepToBook
            number={T('home.how_it_works.3_number')}
            title={T('home.how_it_works.3_request')}
            description={T('home.how_it_works.3_request_description')}
          />
          <StepToBook
            number={T('home.how_it_works.4_number')}
            title={T('home.how_it_works.4_pay')}
            description={T('home.how_it_works.4_description')}
          />
        </div>
      </SectionLayout.Container>
    </SectionLayout>
  );
};
