import React from 'react';

import { SearchCityData } from '@ha/api/v2/searchCity';
import { UserSearch } from '@ha/api/v2/types/UserSearch';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useIntl } from 'ha/i18n';
import { useServices } from 'ha/services';

export const useSearchCityData = ({
  city,
  countryCode,
}: Pick<UserSearch, 'city' | 'countryCode'>) => {
  const { lang } = useIntl();
  const { apiV2 } = useServices();

  const [isFetching, setIsFetching] = React.useState(true);

  const [searchCityData, setSearchCityData] =
    React.useState<SearchCityData | null>(null);

  const searchCity = React.useCallback(async () => {
    try {
      if (!city || !countryCode) return;

      const { data } = await apiV2.searchCity({
        query: [city, countryCode].join('--'),
        languages: [lang],
      });

      setSearchCityData(data);
    } catch (error) {
      reportError(
        new Error('fetching searchCityData failed', {
          cause: error,
        }),
      );
    } finally {
      setIsFetching(false);
    }
  }, [apiV2, lang, city, countryCode]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    searchCity();
  }, [searchCity]);

  return {
    isFetching,
    searchCityData,
  };
};
