import React from 'react';

import { useSelector } from 'react-redux';

import { FavoriteUnitType } from '@ha/api/v2/types/inventory/FavoriteUnitType';
import { UnitType } from '@ha/api/v2/types/inventory/UnitType';

import { HOME } from 'ha/constants/pageNames';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useIntl } from 'ha/i18n';
import { normalizeApiV2Listing } from 'ha/models/Listing/normalizeApiV2Listing';
import { NormalizedAlgoliaListing } from 'ha/models/Listing/types';
import { RudderstackEventNames } from 'ha/modules/Analytics/constants';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';
import { getUserId } from 'ha/modules/AuthLogic/selectors';
import { useServices } from 'ha/services';
import { ListingCardLocations } from 'ha/types/ListingCard';

import { AppSliderSkeleton } from 'ha/components/Redesign/AppSlider/AppSliderSkeleton';
import { ListingCard } from 'ha/components/Redesign/ListingCard';

import { ListingsSlider } from '../../../../modules/ListingsSlider';

import { FAVORITE_SLIDER_ITEMS_LIMIT } from './contants';
import { FavoriteListingsEmptyStatePlaceHolder } from './FavoriteListingsPlaceHolder';

const EVENT_VIEW_MORE_CLICKED = 'Clicked View More';

const extractUnitTypes = (favorites: FavoriteUnitType[]) => {
  return favorites
    .filter(
      (listing): listing is FavoriteUnitType & { unitType: UnitType } =>
        listing.unitType !== undefined,
    )
    .map(listing => ({
      ...listing.unitType,
    }));
};

const reportFetchError = (error: unknown) => {
  reportError(
    new Error('Unable to fetch favorite properties', {
      cause: error,
    }),
  );
};

interface Props {
  isActive: boolean;
}

export const FavoriteListingsSlider = ({ isActive }: Props) => {
  const trackEvent = useTrackEvent();
  const { urlResolver } = useIntl();
  const { apiV2 } = useServices();
  const userId = useSelector(getUserId)!;
  const [favoriteListings, setFavoriteListings] = React.useState<UnitType[]>(
    [],
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchFavoriteListings = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await apiV2.getFavoriteUnitTypes({
        query: {
          userId,
          expand: 'unit-type,photos',
          limit: FAVORITE_SLIDER_ITEMS_LIMIT,
        },
      });

      const unitTypes = extractUnitTypes(data.favorites);
      setFavoriteListings(unitTypes);
    } catch (error) {
      reportFetchError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [apiV2, userId]);

  React.useEffect(() => {
    if (isActive) {
      fetchFavoriteListings();
    }
  }, [fetchFavoriteListings, isActive]);

  const handleCardClick = React.useCallback(
    (listing: NormalizedAlgoliaListing) => {
      trackEvent(RudderstackEventNames.ListingCardClicked, {
        page: HOME,
        component: ListingCardLocations.myFavorites,
        listingCity: listing.city,
        listingCountry: listing.country,
      });
    },
    [],
  );

  const handleViewMoreClick = React.useCallback(() => {
    trackEvent(EVENT_VIEW_MORE_CLICKED, {
      page: HOME,
      component: ListingCardLocations.myFavorites,
    });
  }, []);

  const getSliderItems = () => {
    const listings = favoriteListings.map(unitType => {
      const normalizedListing = normalizeApiV2Listing(unitType);
      return {
        key: unitType.id,
        element: (
          <ListingCard
            room={normalizedListing}
            withGallery={false}
            withFavorites
            onCardClick={() => handleCardClick(normalizedListing)}
          />
        ),
      };
    });

    const hasMoreSlides = listings.length >= FAVORITE_SLIDER_ITEMS_LIMIT;

    if (hasMoreSlides) {
      listings.push({
        key: favoriteListings.length + 1,
        element: (
          <ListingsSlider.CardWithButton
            href={urlResolver.getFavoritesUrl()}
            onClick={handleViewMoreClick}
          />
        ),
      });
    }

    return listings;
  };

  if (isLoading) return <AppSliderSkeleton />;

  if (!isLoading && favoriteListings.length < 1) {
    return <FavoriteListingsEmptyStatePlaceHolder />;
  }

  return (
    <ListingsSlider
      sliderItems={getSliderItems()}
      data-test-locator="FavoriteListingsSlider"
    />
  );
};
