import React from 'react';

import { UnitType } from '@ha/api/v2/types/inventory/UnitType';

import { Cookies as COOKIE_KEYS } from 'ha/constants/Cookies';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useServices } from 'ha/services';
import { setInIndexedDB, getFromIndexedDB } from 'ha/utils/indexedDb';

export const MAX_LISTINGS_LIMIT = 8;
export const MIN_LISTINGS_COUNT = 1;
export const STORAGE_KEY = 'RECENTLY_VIEWED_LISTINGS';

export const useRecentlyViewedListings = () => {
  const { cookie } = useServices();

  const getListings = React.useCallback(async (): Promise<UnitType[]> => {
    try {
      const listingsFromDb = await getFromIndexedDB(STORAGE_KEY);
      const listings = Array.isArray(listingsFromDb) ? listingsFromDb : [];

      if (listings.length < MIN_LISTINGS_COUNT) {
        cookie.removeCookie(COOKIE_KEYS.HAS_RECENTLY_VIEWED_LISTINGS);
      }

      return listings;
    } catch (error) {
      reportError(
        new Error('Failed to load recently viewed listings from indexedDb', {
          cause: error,
        }),
      );
      return [];
    }
  }, [cookie]);

  const addListing = React.useCallback(
    async (unitType: UnitType) => {
      try {
        if (!unitType.id) return;

        let currentListings = await getListings();

        // Remove existing item to avoid duplication
        currentListings = currentListings.filter(
          item => item.id !== unitType.id,
        );

        // Enforce maximum limit by removing the last item if needed
        if (currentListings.length >= MAX_LISTINGS_LIMIT) {
          currentListings = currentListings.slice(0, -1);
        }

        const newList = [unitType, ...currentListings];

        // Set cookie if the new list meets minimum count for section enablement
        const meetsMinimumRequirement = newList.length >= MIN_LISTINGS_COUNT;
        if (meetsMinimumRequirement) {
          cookie.setCookie(COOKIE_KEYS.HAS_RECENTLY_VIEWED_LISTINGS, 'true');
        }

        await setInIndexedDB(STORAGE_KEY, newList);
      } catch (error) {
        reportError(
          new Error('Failed to add recently viewed listing to indexedDb', {
            cause: error,
          }),
        );
      }
    },
    [cookie, getListings],
  );

  const hasRecentlyViewedListing = React.useMemo(() => {
    const value = cookie.getCookie(COOKIE_KEYS.HAS_RECENTLY_VIEWED_LISTINGS);
    return value === 'true';
  }, [cookie]);

  return {
    hasRecentlyViewedListing,
    addRecentlyViewedListing: addListing,
    getRecentlyViewedListings: getListings,
  };
};
