import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { AppSlider, AppSliderProps } from 'ha/components/Redesign/AppSlider';
import { AppSliderSkeleton } from 'ha/components/Redesign/AppSlider/AppSliderSkeleton';

import { RebrandThemeProvider } from '../ThemeProvider';

import { ListingsSliderCardWithButton } from './ListingsSliderCardWithButton';

interface Props {
  sliderItems: AppSliderProps['items'];
  'data-test-locator'?: string;
}

const ListingsSlider = ({
  sliderItems,
  'data-test-locator': dataTestId,
}: Props) => {
  const theme = useTheme();
  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

  const sliderPerView = React.useMemo(() => {
    if (isLargerThanLg) return 4;
    if (isLargerThanMd) return 3;
    if (isLargerThanSm) return 2;

    return 1.2;
  }, [isLargerThanLg, isLargerThanMd, isLargerThanSm]);

  const spaceBetween = React.useMemo(() => {
    if (isLargerThanMd) return theme.utils.spacing('ref/spacing/10');

    return theme.utils.spacing('ref/spacing/6');
  }, [isLargerThanMd, theme.utils]);

  const shouldShowControls =
    isLargerThanMd && sliderItems.length > sliderPerView;

  return (
    <AppSlider
      fallback={<AppSliderSkeleton mobileHeight={280} desktopHeight={280} />}
      withControls={shouldShowControls}
      items={sliderItems}
      slidesPerView={sliderPerView}
      spaceBetween={parseInt(spaceBetween, 10)}
      data-test-locator={dataTestId}
    />
  );
};

const ListingsSliderWrapper = ({ ...rest }: Props) => {
  return (
    <RebrandThemeProvider>
      <ListingsSlider {...rest} />
    </RebrandThemeProvider>
  );
};

ListingsSliderWrapper.CardWithButton = ListingsSliderCardWithButton;

export { ListingsSliderWrapper as ListingsSlider };
