import React from 'react';

import { Theme } from '@mui/material';

import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@hbf/dsl/core';
import { Grid } from '@hbf/dsl/legacy';

import { RadioButtonCard } from 'ha/ui/RadioButtonCard';

const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    marginBottom: theme.spacing(1.5),
  },
}));

interface Props {
  onMonthClick: (month: Date) => void;
  value?: Date;
}

export const MonthAvailability: React.FC<Props> = ({ onMonthClick, value }) => {
  const currentMonth = dayjs().endOf('month');
  const { classes } = useStyles();

  const [monthSelected, setMonthSelected] = React.useState<string>(
    value ? dayjs(value).format('YYYY-MM-DD') : '',
  );

  // TODO: refactor
  // eslint-disable-next-line react/no-unstable-nested-components
  const RadioOption: React.FC<{ month: number; isLast: boolean }> = ({
    month,
    isLast,
  }) => {
    const date = currentMonth.add(month, 'M');
    const monthValue = date.format('YYYY-MM-DD');

    const isActive = monthSelected === monthValue;

    return (
      <Grid item key={month} className={isLast ? undefined : classes.item}>
        <RadioButtonCard
          titleContent={date.format('MMMM YYYY')}
          onClick={() => {
            setMonthSelected(monthValue);
            onMonthClick(date.toDate());
          }}
          handleChange={() => setMonthSelected(monthValue)}
          value={monthValue}
          isActive={isActive}
          ContentComponent={({ children }) => (
            <Typography
              variant="body/sm-regular"
              fontWeight={isActive ? 'bold' : undefined}
              color={isActive ? 'primary' : 'default'}
            >
              {children}
            </Typography>
          )}
        />
      </Grid>
    );
  };

  return (
    <Grid container direction="column">
      {dayjs.months().map((_, index, months) => (
        <RadioOption month={index} isLast={index === months.length - 1} />
      ))}
    </Grid>
  );
};
