import { PropertyType } from '@ha/algolia';
import { UnitType } from '@ha/api/v2/types/inventory/UnitType';
import {
  Listing,
  ListingKind,
  ListingPhotoType,
  ListingType,
} from '@ha/api/v2/types/Listing';
import { isNorthAmericanCountry } from '@ha/core/SupportedCountries';

import { Currency } from 'ha/constants/Currencies';

import { convertSquareMeterToSquareFootString } from 'ha/helpers/unitConverter';
import NoPhotoImg from 'ha/img-src/static/nophotos.png';

import { getUnitTypeMinPrice } from 'ha/modules/Listing/Pricing/getUnitTypeMinPrice';

import { isListingFurnished } from '../ListingFacilities/isListingFurnished';
import { getUtilityBillsFromCosts } from '../ListingUtilityBills/getUtilityBillsFromCosts';

import { NormalizedAlgoliaListing } from './types';

interface CurrencyRates {
  [key: string]: number;
}

export const TypesPlatform = {
  [ListingType.House]: PropertyType.house,
  [ListingType.Building]: PropertyType.building,
  [ListingType.Apartment]: PropertyType.apartment,
};

export const KindsPlatform = {
  [ListingKind.EntirePlace]: 'ENTIRE_PLACE',
  [ListingKind.PrivateRoom]: 'PRIVATE_ROOM',
  [ListingKind.SharedRoom]: 'SHARED_ROOM',
};

const getConvertedSize = (size: string | null, isImperialSystem: boolean) => {
  if (!size) return undefined; // return undefined for null sizes
  return !isImperialSystem
    ? size
    : convertSquareMeterToSquareFootString(parseFloat(size));
};

const permissivePhotoTypes: ListingPhotoType[] = ['photo'];

export function normalizeApiV2Listing(
  listing: Listing | UnitType,
  currency?: string,
  currencyRates?: CurrencyRates,
): NormalizedAlgoliaListing {
  const photos =
    listing.photoURLList
      ?.filter(photo => permissivePhotoTypes.includes(photo.type))
      .map(photo => photo.url) ?? [];

  const floorPlanCount =
    listing.photoURLList?.filter(photo => photo.type === 'floorplan').length ??
    0;
  const videoCount = listing.videoURLList?.length ?? 0;

  let propertyType: PropertyType = PropertyType.apartment;
  const kindLabel = KindsPlatform[listing.kind];
  if (
    kindLabel === 'ENTIRE_PLACE' &&
    listing.facilities.bedroom_count === '0'
  ) {
    propertyType = PropertyType.studio; // STUDIO
  } else if (kindLabel === 'ENTIRE_PLACE') {
    propertyType = TypesPlatform[listing.type]; // APARTMENT, HOUSE, BUILDING
  } else {
    propertyType = kindLabel as PropertyType; // PRIVATE_ROOM, SHARED_ROOM
  }

  const listingCurrency = currency ?? listing.currency;
  const currencyConversion =
    currencyRates && currency
      ? currencyRates[listingCurrency] / currencyRates[listing.currency]
      : 1;

  let unitTypeId = listing.id;
  if ('unitTypeId' in listing) {
    unitTypeId = listing.unitTypeId;
  }
  const isImperialSystem = isNorthAmericanCountry(listing.countryCode);

  return {
    id: listing.id,
    price: Math.round(listing.price * currencyConversion),
    currency: listingCurrency as Currency,
    street: listing.street,
    city: listing.city,
    country: listing.country,
    isNew: listing.isNew,
    previewImage: (photos && photos[0]) ?? NoPhotoImg,
    photos,
    photoCount: photos.length,
    floorPlanCount,
    videoCount,
    minimalRentalPeriod: listing.minimumStayMonths,
    maximumStay: listing.maximumStay,
    listingPath:
      'unitTypePath' in listing ? listing.unitTypePath : listing.listingPath,
    unitTypePath: (listing as UnitType)?.unitTypePath,
    propertyType,
    currentOccupancy: listing.currentOccupancy,
    freePlaces: listing.freePlaces,
    exclusions: listing.exclusions ?? undefined,
    utilities: listing.costs
      ? getUtilityBillsFromCosts(listing.costs.costs)
      : undefined,
    bedroomFurnished: isListingFurnished(listing) ? 'yes' : 'no',
    bedroomCount: listing.facilities.bedroom_count ?? undefined,
    studentHousing: listing.facilities.student_housing ?? undefined,
    coLiving: listing.facilities.co_living ?? undefined,
    totalSize: getConvertedSize(
      listing.facilities.total_size,
      isImperialSystem,
    ),
    bedroomSize: getConvertedSize(
      listing.facilities.bedroom_size,
      isImperialSystem,
    ),
    registrationPossible: listing.facilities.registration_possible ?? undefined,
    priceType: listing.pricingType,
    minPrice: Math.round(
      (getUnitTypeMinPrice(listing) * currencyConversion) / 100,
    ),
    unitTypeId,
    rentalPeriodType: listing.rentalPeriodType,
    strictRentalPeriods: listing.strictRentalPeriods ?? undefined,
    countryCode: listing.countryCode,
    propertyId: (listing as UnitType)?.propertyId,
    pricingValues: listing.pricingValues,
    pricingValuesEUR: listing.pricingValuesEUR,
    exclusivityPartnerIDs:
      'exclusivityPartnerIDs' in listing ? listing.exclusivityPartnerIDs : [0],
  };
}
